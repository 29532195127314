import React from 'react';
import clsx from 'clsx';

interface QuoteProps {
  color?: string;
}


export const Quote: React.FC<QuoteProps> = ({ children, color = 'hs' }) => {
   const isMs = color === 'ms'
   return (
      <div className={clsx("p-6 pt-4 leading-8 text-gray-500 bg-white border-l-2 border-solid shadow-xs md:text-xl md:leading-9 md:pl-10 md:pr-8 md:pt-6 md:pb-8", {
         'border-purple-800': isMs,
         'border-indigo-800': !isMs
      })}>{children}</div>
    );
}

export default Quote;
