import React, { ReactNode } from "react";

type HighlightProps = {
  children: ReactNode;
}

const Highlight = ({ children } : HighlightProps) => {
  return (
    <p className="text-lightError font-bold my-4">{children}</p>
  )
}

export default Highlight;
